<template>
  <div>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          max-width="600"
      >
        <v-card style="height: 350px;">
          <v-card-title class="text-h5" style="justify-content: center">
            <img style="width: 75px;" src="../assets/img/warning_12461956.png" alt="">
          </v-card-title>

          <v-card-text style="font-size: 35px; line-height: 45px;">
            {{ErrorText}}
          </v-card-text>

          <v-card-actions
              style=" position: absolute; bottom: 3px; right: 0; "
          >
            <v-spacer></v-spacer>
            <v-btn
                color="warning darken-1"
                text
                @click="dialog = false"
                style="padding: 27px 40px;"
            >
              {{$t('close')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      ErrorText:''
    }
  },
  methods:{
    open(){
      this.dialog = true
    }
  },
  mounted() {
    this.$root.$on('warning', (data) => {
      this.ErrorText = data.text
      this.open()
    })
  }
}
</script>

<style lang="scss" scoped>
.text-h5{
  img{
    width: 100px;
  }
}

.v-card__text{
  text-align: center;
}
</style>

<style lang="scss">
.error_server{
  .v-dialog.v-dialog--active{
    max-width: 600px !important;
  }
}
</style>
