<template>
  <div class="text-center">
    <v-overlay :value="overlay">
      <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data: () => ({
    overlay: false,
  }),

  methods:{
    open(data){
      this.overlay = data
    }
  },

  watch: {
    overlay (val) {
      val && setTimeout(() => {
        this.overlay = false
      }, 20000)
    },
  },

  mounted() {
    this.$root.$on('loading', (data) => {
      this.open(data)
    })
  }
}
</script>
