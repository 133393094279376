<template>
  <div>
  </div>
</template>

<script>
export default {
  methods: {
    open1() {
      this.$notify({
        title: this.$t('successful_title'),
        message: this.$t('successful_text'),
        type: 'success'
      });
    },

    openSend() {
      this.$notify({
        title: this.$t('send_title'),
        message: this.$t('send_text'),
        type: 'success'
      });
    },

    open2() {
      this.$notify({
        title: 'Warning',
        message: 'This is a warning message',
        type: 'warning'
      });
    },

    open3() {
      this.$notify.info({
        title: 'Info',
        message: 'This is an info message'
      });
    },

    open4() {
      this.$notify.error({
        title: 'Error',
        message: 'This is an error message'
      });
    }
  },
  mounted() {
    this.$root.$on('SuccessNotification', ()=>{
      this.open1()
    })

    this.$root.$on('SendNotification', ()=>{
      this.openSend()
    })
  }
}
</script>
