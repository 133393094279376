<template>
  <div class="text-center snack_bar">
    <v-snackbar
        v-model="data.snackbar"
        :timeout="data.timeout"
    >
      {{ data.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="data.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    data:{
      snackbar: false,
      text: '',
      timeout: null,
    }
  }),
  methods:{
    open(){
      this.data.snackbar = true
    }
  },
  mounted() {
    this.$root.$on( 'LoginTrue' ,(data) => {
      this.data.text = data.text
      this.data.timeout = data.timeout
      this.open()
    })
  }
}
</script>

<style lang="scss">
.text-center.snack_bar{
  .v-btn__content{
    font-size: 14px;
  }
  .v-snack__wrapper.theme--dark {
    background-color: #4caf50;
    color: hsla(0, 0%, 100%, 0.87);
  }
  .blue--text{
    color: white !important;
  }
}
</style>
