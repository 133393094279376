<template>
  <v-card
      class="mx-auto"
      width="313"
  >
    <v-list>
      <v-list-item-group
          v-model="model"
          mandatory
          color="indigo"
      >
        <v-list-item to="/" v-if="['admin', 'center', 'operator'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiHome"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('republic_norms') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/regions" v-if="['admin', 'center'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiCallSplit"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('regions') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/prisons" v-if="['admin', 'center'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiDomain"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('prisons') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div style="position: relative;">
          <v-list-item style="position: relative; z-index: 2;" :to="this.role === 'operator' ? '/products' : '/product-center'" v-if="['admin', 'operator', 'center'].includes(role)">
            <v-list-item-icon>
              <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiFoodDrumstick"></svg-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t('products') }}</v-list-item-title>
            </v-list-item-content>


          </v-list-item>

          <div v-click-outside="hide" v-if="me.notifications&&me.notifications.jails&&me.notifications.jails.length>0" @click="open_notification" class="product_notification">{{me.notifications&&me.notifications.jails&&me.notifications.jails.length}}</div>

          <div class="product_notification_list" :class="{'active' : notificationStatus}">
            <div class="product_notification_list_title">
              {{$t('list_institutions')}}: {{me.notifications&&me.notifications.jails&&me.notifications.jails.length}}
            </div>
            <div class="product_notification_list_text">
              {{$t('reduced_quantity_products')}}
            </div>
            <div class="product_notification_list_info" v-for="(item, index) in me.notifications&&me.notifications.jails" :key="index">
              <div class="product_notification_list_info_text" @click="reduced_quantity_products(item)">{{index+1}}. {{item.number}}</div>
            </div>
          </div>
        </div>


        <v-list-item to="/invoice_order" v-if="['operator'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiReceiptText"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Накладной ордер</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/barn_report" v-if="['operator'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiBarn"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('warehouse_report') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/number_people_create_date" v-if="['operator'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiAccountGroup"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('number_of_people_per_day') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/about_changes" v-if="['admin'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiFileArrowLeftRight"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('notification') }}</v-list-item-title>
          </v-list-item-content>
          <v-badge
              v-if="counter_chack"
              color="pink"
              :content="counter + plus"
              style="top: -6px; right: 12px;"
          >
          </v-badge>
        </v-list-item>


        <v-list-item to="/product_create" v-if="['admin'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiFood"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('adding_product') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/power_attorney_number" v-if="['operator', 'center'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiBarcodeScan"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('attorney') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/users" v-if="['admin', 'center'].includes(role)">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiAccount"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('employees') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/report">
          <v-list-item-icon>
            <svg-icon class="app_aside_icons" type="mdi" :path="path.mdiChartBox"></svg-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('report') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <span></span>


<!--        <v-list-item to="/about4" >-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>mdi-star</v-icon>-->
<!--          </v-list-item-icon>-->

<!--          <v-list-item-content>-->
<!--            <v-list-item-title>{{ $t('about_the_system') }}</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->


      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import * as mdiIcons from '@mdi/js'
export default {
  data(){
    return{
      model: 1,
      count: null,
      counter: 0,
      counter_chack: false,
      notificationStatus: false,
      plus: '',
      path: mdiIcons,
    }
  },
  components: {
    SvgIcon
  },
  watch:{
    notification_len: function() {
      let dd = []

      this.notification_len.forEach(elem => {
        if (elem.has_permission == false){
          dd.push(elem)
        }
      })
      if (dd.length>0 && dd.length<10){
        this.counter_chack = true
        this.counter = dd.length
      } else if (dd.length>=10){
        this.counter_chack = true
        this.counter = 9
        this.plus = '+'
      }
    }
  },
  computed:{
    role() {
      return this.$store.state.role
    },
    me() {
      return this.$store.state.me
    },
    notification_len() {
      return this.$store.state.notification_len
    }
  },
  methods:{
    reduced_quantity_products(item){
      this.$router.push({
        path: this.role === 'operator' ? '/products' : '/product-center',
        query: {
          center_id: item.center,
          jail_id: item.id
        }
      })
    },
    send_for_permission(data){
      if (data.length>0){
        this.count = data.length
      }
    },
    open_notification(){
      this.notificationStatus = !this.notificationStatus
    },
    hide() {
      this.notificationStatus = false
    }
  },
}
</script>

<style lang="scss" scoped>
  .app_aside_icons{
    color: white;
  }
  .product_notification{
    background: red;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    position: absolute;
    z-index: 2;
    top: 18px;
    right: 10px;
    cursor: pointer;
    //&:hover~.product_notification_list{
    //  height: 247px;
    //  overflow-y: scroll;
    //  transition: all ease .4s;
    //}
  }
  .product_notification_list{
    position: absolute;
    width: 313px;
    border-radius: 9px;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);
    left: 0;
    z-index: 2;
    top: 60px;
    height: 0;
    overflow: hidden;
    transition: all ease .4s;
    &:hover{
      height: 247px;
      overflow-y: scroll;
      transition: all ease .4s;
    }
    &.active{
      height: 247px;
      overflow-y: scroll;
      transition: all ease .4s;
    }
    &_title{
      color: rgb(0, 70, 109);
      font-family: Inter;
      font-size: 14.78px;
      font-weight: 700;
      line-height: 18px;
      padding: 9px 0px 4px 12px;
    }
    &_text{
      color: rgb(151, 151, 151);
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      padding: 0px 0px 8px 12px;
    }
    &_info{
      color: rgb(0, 70, 109);
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      background: rgba(255, 0, 0, 0.1);
      margin-bottom: 6px;
      cursor: pointer;
      &_text{
        margin-left: 12px;
        padding: 16px 0px;
      }
    }
  }
</style>
