import createPersistedState from "vuex-persistedstate";

const storage = window.localStorage
const storageKey = "JIED_STORAGE"
const versionKey = "JIED_STORAGE_STORE_VERSION"
const currentVersion = "1.3"

export default createPersistedState({
  key: storageKey,
  storage: {
    getItem() {
      if (storage.getItem(versionKey) !== currentVersion) {
        storage.setItem(versionKey, currentVersion)
        return null
      } else {
        return storage.getItem(storageKey)
      }
    },
    setItem: (key, value) => storage.setItem(key, value),
    removeItem: (key) => storage.removeItem(key)
  },
})
