import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'republic_norms',
    component: () => import('../views/Republic/RepublicNorms.vue'),
    meta: { requiresAuth: true, permission:['admin','center','operator'] },
  },
  {
    path: '/republic_norms/republic_standards',
    name: 'republic_standards',
    component: () => import('../views/Republic/RepublicStandards.vue'),
    meta: { requiresAuth: true, permission:['admin','center','operator'] },
  },
  {
    path: '/republic_norms/republic_standards/product',
    name: 'standards_index',
    component: () => import('../views/Republic/RepublicStandardsIndex.vue'),
    meta: { requiresAuth: true, permission:['admin','center','operator'] },
  },
  {
    path: '/prisons',
    name: 'prisons',
    component: () => import('../views/JiekInfo/JieksView'),
    meta: { requiresAuth: true, permission:['admin','center'] },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/LoginPage'),
    meta: { requiresAuth: true, permission:['admin','center'] },
  },
  {
    path: '/regions',
    name: 'regions',
    component: () => import('../views/RegionsNew/RegionView.vue'),
    meta: { requiresAuth: true, permission:['admin','center'] },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/ProductsMenu/ProductsMenu'),
    meta: { requiresAuth: true, permission:['operator'] },
  },
  {
    path: '/product-center',
    name: 'product-center',
    component: () => import('../views/ProductsMenu/ProductsMenuCenter'),
    meta: { requiresAuth: true, permission:['admin','center'] },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/Users/UsersViwe'),
    meta: { requiresAuth: true, permission:['admin','center'] },
  },
  {
    path: '/invoice_order',
    name: 'invoice_order',
    component: () => import('../views/InvoiceOrder/InvoiceOrder2.vue'),
    meta: { requiresAuth: true, permission:['admin', 'center', 'operator'] },
  },
  {
    path: '/invoice_order_print',
    name: 'invoice_order_print',
    component: () => import('../views/InvoiceOrder/InvoiceOrderPrintForOperator.vue'),
    meta: { requiresAuth: true, permission:['operator'] },
  },
  {
    path: '/barn_report',
    name: 'barn_report',
    component: () => import('../views/BarnReport/BarnReport.vue'),
    meta: { requiresAuth: true, permission:['center', 'admin','operator'] },
  },
  {
    path: '/number_people_create_date',
    name: 'number_people_create_date',
    component: () => import('../views/NumberPeopleDay/NumberPeopleCreateDate.vue'),
    meta: { requiresAuth: true, permission:['center','operator'] },
  },
  {
    path: '/number_people_create_date/number_people_new_create',
    name: 'number_people_new_create',
    component: () => import('../views/NumberPeopleDay/NumberPeopleNewCreate.vue'),
    meta: { requiresAuth: true, permission:['center','operator'] },
  },
  {
    path: '/about_changes',
    name: 'about_changes',
    component: () => import('../views/AboutChanges/AboutChanges'),
    meta: { requiresAuth: true, permission:['admin'] },
  },
  {
    path: '/product_create',
    name: 'product_create',
    component: () => import('../views/ProductCreate/ProductCreate.vue'),
    meta: { requiresAuth: true, permission:['admin'] },
  },
  {
    path: '/product_create/product_group',
    name: 'product_group',
    component: () => import('../views/ProductCreate/productGroup.vue'),
    meta: { requiresAuth: true, permission:['admin'] },
  },
  {
    path: '/power_attorney_number',
    name: 'power_attorney_number',
    component: () => import('../views/PowerAttorneyNumber/PowerAttorneyNumber'),
    meta: { requiresAuth: true, permission:['center', 'operator'] },
  },
  {
    path: '/print_invoice_order',
    name: 'print_invoice_order',
    component: () => import('../components/PrintInvoiceOrder'),
    meta: { requiresAuth: true, permission:['center', 'operator'] },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('../views/Report/expense_reports.vue'),
    meta: { requiresAuth: true, permission:['admin', 'center', 'operator'] },
  },
  {
    path: '/notfound',
    name: 'notfound',
    meta: { requiresAuth: true, title:"404", permission:['admin','center','operator'] },
    component: () => import('../views/Status/NotFound')
  },
  {
    path: '/**',
    name: '404',
    meta: { requiresAuth: true, title:"404", permission:['admin','center','operator'] },
    component: () => import('../views/Status/NotFound')
  },
]


const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  }
})
router.beforeEach(async  (to, from, next) => {


  if(to.name!=='login' && !localStorage.access){
    return next('/login')
  }
  else{
    if(to.name!=='login' &&  to.meta.requiresAuth){
        if(to.meta.permission.includes(localStorage.role)){
          if(!store.state.refresh){
            await store.dispatch('get_me')
                .then(()=>{
                  return next()
                })
                .catch(()=>{
                  if(store.dispatch('refresh_token')){ return next() }
                  else{ return next({ name: 'login' }) }
                })
          }
          else{
            return next()
          }
        }
        else{
          return next('/notfound')
        }
    }
    else{
      next()
    }
  }
  return next()
})

export default router
