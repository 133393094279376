import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import persistedState from './persistedState'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    me:{},
    refresh:false,
    loader:false,
    denied_admin_api: ['admin','center','operator'],
    dictionary:{
        standard: [],
        product: [],
        unit: [],
        region: [],
    },
    role: localStorage.role? localStorage.role:'',
    notification_len:[]
  },
  getters: {
      getNotification(state){
          return state.notification_len
      }
  },
  mutations: {
    set_dictionary(state, { dictionary, type }) {
      state.dictionary[type] = dictionary;
    },
    set_me(state, {payload}){
      state.me=payload
      state.role=payload.role.code
    },
    set_notification(state, {payload}){
      state.notification_len=payload
    },
    set_refresh(state, {payload}){
      state.refresh=payload
    },
    set_loader(state, {payload}){
      state.loader=payload
    },
  },
  actions: {
      notification({commit}){
          axios.get(`/users/`)
              .then(res => {
                  commit('set_notification', { payload:res.data.results})
              })
      },
      get_dictionary({ commit, state }) {
          Object.keys(state.dictionary).filter(resp => {
              axios.get(`/dictionary/${resp}/`)
                  .then((response) => {
                      commit('set_dictionary', {
                          dictionary: response.data,
                          type: resp
                      })
                  })
          })
      },
    get_me({ commit, dispatch }){
      return new Promise((resolve, reject)=>{
        commit('set_loader', { payload:true})
        axios.get('/users/me/')
            .then(res=>{
                if (res.status === 404){
                    this.$router.push('/login')
                }
              commit('set_me', { payload:res.data})
              localStorage.role=res.data.role.code
              dispatch('get_dictionary')
              dispatch('chage_refresh')
              if(res.data.role.code =='admin'){
                  dispatch('notification')
              }
              commit('set_loader', { payload:false})
              // commit('set_profile', {payload:res.data.profile})
              return  resolve(res)

            })
            .catch(e=>{
                commit('set_loader', { payload:false})
                return reject(e)
            })
      })
    },
    chage_refresh({commit}){
      commit('set_refresh', { payload:true})
      setTimeout(function(){
        commit('set_refresh', { payload:false})
      }, 900000);
    },
    refresh_token(){
      return new Promise((resolve, reject)=>{
        axios.post('/token/refresh/', {refresh: localStorage.refresh})
            .then(res=>{
              localStorage.token = res.data.access;
              axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.access}`;
              resolve()
            })
            .catch(()=>{
              reject()
            })
      })
    },
    get_loader({commit}, value){
      commit('set_loader', { payload:value})
    },
  },
  modules: {
  },
  plugins: [persistedState]

})
