import Vue from 'vue'
import axios from 'axios';
import router from '../router/index'
let base_url = process.env.VUE_APP_BASE_URL
axios.defaults.baseURL = base_url
axios.defaults.headers.common[ "Authorization" ] = `Bearer ${localStorage.access}`;
axios.defaults.headers.common[ "ngrok-skip-browser-warning" ] = true;
global.axios = axios


let isRefreshing=false
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

axios.interceptors.response.use(
  (response) => {
    isRefreshing=false
    return response
  },
  (error)=>{
    const orginal = error.config
    if(((error.response.data.code=="token_not_valid" && error.config.url=='/token/refresh/')|| (error.config.url=='/token/refresh/' && error.response.status)) && router.currentRoute.name!='oneid'){
        router.push("/login").catch(e => { if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) { console.log(e) }});
    }
    if ((error.response.status === 401 || error.response.status === 403) && !orginal._retry && router.currentRoute.name!='login'&&router.currentRoute.name!='oneid' ) {
      if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                failedQueue.push({resolve, reject})
                }).then(token => {
                orginal.headers['Authorization'] = 'Bearer ' + token;
                return axios(orginal);
                }).catch(err => {
                return Promise.reject(err);
                })
      }
      isRefreshing=true
      orginal._retry=true
      return new Promise(function (resolve, reject) {
        axios.post('/token/refresh/', {refresh: localStorage.refresh})
        .then(res=>{
            localStorage.access = res.data.access;
            axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.access}`;
            orginal.headers["Authorization"] = `Bearer ${res.data.access}`;
            processQueue(null, res.data.access);
            resolve(axios(orginal));
        })
        .catch(e=>{
            processQueue(e, null);
            reject(e);
        })
        .finally(()=>{
            isRefreshing=false;
        })
      })
    }
    return Promise.reject(error);
  });

Vue.prototype.axios = axios



