import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import './api/index'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import i18n from '@/plugins/i18n';
import './assets/css/materialdesignicons.css';


import NProgress from 'vue-nprogress'
const nprogress = new NProgress()


const optionsSwal = {
  confirmButtonColor: '#004787',
  cancelButtonColor: '#004787',

};



Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
Vue.use(VueSweetalert2, optionsSwal)
Vue.use(NProgress)




new Vue({
  i18n,
  router,
  store,
  vuetify,
  nprogress,
  render: h => h(App)
}).$mount('#app')
